const providers = {
  midnite: {
    PROVIDER_KEY: "midnite",
    PROVIDER_LOGO:
      "https://assets-global.website-files.com/5fa17832c5912591f494f9ed/62529bc1fbc13f0d1e92e45f_midnitetopleftlogo.svg",
    PROVIDER_URL:
      "https://welcome.midnite.com/counter-strike?utm_source=DRAFT5",
    BACKGROUND_IMAGE: "",
    colors: {
      TEXT: "#FFF",
      ACCENT: "#c3fb7c",
      BACKGROUND: "#363c44",
      BACKGROUND_DARKER: "#20252c",
      HOVER_COLOR: "#3b4148",
      HOVER_TEXT_COLOR: "#3b4148",
    },
  },
  ggbet: {
    PROVIDER_KEY: "ggbet",
    PROVIDER_LOGO: "https://static.draft5.gg/partners/ggbet/logo.svg",
    PROVIDER_URL: "",
    BACKGROUND_IMAGE: "",
    colors: {
      TEXT: "#FFF",
      ACCENT: "#ff9c00",
      BACKGROUND: "#363c46",
      BACKGROUND_DARKER: "#20252c",
      HOVER_COLOR: "#11151a",
      HOVER_TEXT_COLOR: "#11151a",
    },
  },
  betboom: {
    PROVIDER_KEY: "betboom",
    PROVIDER_LOGO: "https://static.draft5.gg/partners/betboom/logo.svg",
    PROVIDER_URL: "https://click.betboom.com/7r6rsvS3?landing=13610&sub_id1=BBD&sub_id2=odds",
    BACKGROUND_IMAGE: "",
    colors: {
      TEXT: "#e8e8e8",
      ACCENT: "#ffd000",
      BACKGROUND: "#494949",
      BACKGROUND_DARKER: "#131313",
      HOVER_COLOR: "#383838",
      HOVER_TEXT_COLOR: "#383838",
    },
  },
  rivalry: {
    PROVIDER_KEY: "rivalry",
    PROVIDER_LOGO: "https://static.draft5.gg/partners/rivalry/logo.svg",
    PROVIDER_URL: "https://www.rivalrybets.com/pt/?affiliate=&tracker=tsxxqehobrtlyxxirgabbuxpm&affiliate_id=309003&affiliate_name=gamersclub2024&utm_source=Gamers+Club+&utm_medium=Paid&utm_campaign=BR-CS2-ReIntroduction-sb4130-2024&utm_id=BR-CS2-ReIntroduction-Live-Betting-sb4130-2024&utm_content=odds+API+banner",
    BACKGROUND_IMAGE: "https://res.cloudinary.com/rivalry/image/upload/fl_lossy,f_auto,q_auto/v1712586815/site/casino/casino-landing-retrowave-grid.png",
    PROVIDER_WARNING: "+18. Seja responsável.",
    colors: {
      TEXT: "#fff",
      TEXT_ACCENT: "#ff802c",
      TEXT_LIGHT: "#cecece",
      ACCENT: "#ff6700",
      BACKGROUND: "#384360",
      BACKGROUND_DARKER: "#1e2433",
      HOVER_COLOR: "#49577b",
      HOVER_TEXT_COLOR: "#FFF",
      ACCENT_VERSUS: "#a0b757",
    },
  },
};

export default providers;
